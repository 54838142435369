<template>
    <div>
        <div class="price-hint"
            v-if="standardGuests">
            <strong>Hinweis:</strong> Unsere Preise beziehen sich auf eine Grundbelegung von bis zu {{getStandardGuests()}}. Für zusätzliche Personen wird ein Aufpreis gemäß nachfolgender Preisliste erhoben.
        </div>
        <table class="table pricelist table-hover">

            <tbody v-for="sg in priceArray">
                <tr class="mainrow">
                    <td>
                        {{sg.service.name}}
                        <small>
                             <span v-if="sg.nightRange">
                                  {{voMsg('nights')}}: {{sg.nightRange.min}} - {{sg.nightRange.max}} 
  										</span>
                                    
                              <span v-if="sg.guestRange">
                                  {{voMsg('guests')}}: {{sg.guestRange.min}} - {{sg.guestRange.max}} 
  										</span>
                           </small>
                    </td>
                    <td class="text-center"><span v-if="sg.price">{{getCalculatedPrice(sg.price)}}</span></td>
                    <td class="text-right">
                        <div v-if="sg.price && sg.service.calculation != 'FLAT'">
                            <span v-if="sg.service.calculation === 'FLAT'">{{voMsg('tpl.service.FLAT')}}</span>
                            <span v-if="sg.service.calculation === 'NIGHT'">{{voMsg('tpl.service.NIGHT')}}</span>
                            <span v-if="sg.service.calculation === 'WEEK'">{{voMsg('tpl.service.WEEK')}}</span>
                            <span v-if="sg.service.calculation === 'MONTH'">{{voMsg('tpl.service.MONTH')}}</span>
                            <small v-if="sg.service.perAdult"> / {{voMsg('adult')}} </small>
                            <small v-if="sg.service.perChild"> / {{voMsg('child')}} </small>
                        </div>
                        <div>
                            <span v-if="sg.service._id === 45288">{{voMsg('tpl.service.PERSON')}}</span>
                        </div>

                    </td>
                </tr>
                <tr v-for="price in sg.lines"
                    class="subrow">
                    <td class="subinfo">
                        <small>{{price.season}}</small>
                        <br/>
                        <span v-if="price.fromdate">{{getFormattedDate(price.fromdate)}} - {{getFormattedDate(price.tilldate)}}</span>
                    </td>
                    <td class="text-center">
                        {{getCalculatedPrice(price.value)}}
                    </td>
                    <td class="text-right">
                        <span v-if="sg.service.calculation === 'FLAT'">{{voMsg('tpl.service.FLAT')}}</span>
                        <span v-if="sg.service.calculation === 'NIGHT'">{{voMsg('tpl.service.NIGHT')}}</span>
                        <span v-if="sg.service.calculation === 'WEEK'">{{voMsg('tpl.service.WEEK')}}</span>
                        <span v-if="sg.service.calculation === 'MONTH'">{{voMsg('tpl.service.MONTH')}}</span>
                        <small v-if="sg.service.perAdult"> / {{voMsg('adult')}} </small>
                        <small v-if="sg.service.perChild"> / {{voMsg('child')}} </small>

                    </td>
                </tr>
            </tbody>
        </table>

    </div>

</template>
<script>
    import V from 'voUtils/V.js';
    import DateEx from 'voUtils/DateEx.js';

    export default {
    	voVueComponent: 'seasonprices',
    	props: {
    		pricelist: String
    	},
    	data: function() {
    		return {
    			priceArray: {},
    			years: Array,
    			unitId: Number,
    			priceBookingFee: Number,
    			priceFinalCleaning: Number,
    			rentalPrices: Array,
    			mandatoryPrices: Array,
    			optionalPrices: Array,
    			surchargePrices: Array,
    			standardGuests: Number
    		};
    	},
    	mounted: function() {
    		var pricelist = JSON.parse(this.pricelist);
    		this.priceArray = pricelist;

    		const guestRangeMin = this.priceArray.find(item => item.service._id === 41384);
    		var standardGuests = null

    		if (guestRangeMin && guestRangeMin.guestRange && guestRangeMin.guestRange.min) {
    			standardGuests = parseInt(guestRangeMin.guestRange.min) - 1;
    		}

    		this.standardGuests = standardGuests;

    	},
    	methods: {
    		getStandardGuests: function() {
    			if (this.standardGuests && this.standardGuests > 1) {
    				return this.standardGuests + ' Personen'
    			} else if (this.standardGuests && this.standardGuests === 1) {
    				return this.standardGuests + ' Person'
    			}
    		},
    		getYearButtonId: function(year) {
    			return 't' + year + '-tab';
    		},
    		getTarget: function(year) {
    			return "#t" + year;
    		},
    		getTabId: function(year) {
    			return "t" + year;
    		},
    		getTabAreaLabel: function(year) {
    			return "t" + year + "-tab";
    		},
    		getFormattedDate: function(date) {
    			return DateEx.formatDate(date, 'dd.MM.yy', 'de');
    		},
    		getCalculatedPrice: function(price) {
    			var rentPrice = 0;
    			var calcPrice = 0;
    			if (price > 0) {
    				rentPrice = price;
    				calcPrice = Number((rentPrice) / 100).toLocaleString("de-DE", {
    					minimumFractionDigits: 2
    				}) + " €";
    			}
    			return calcPrice;
    		},
    		getCalculatedMandatoryPrice: function() {
    			var rentPrice = 0;
    			var calcPrice = 0;
    			calcPrice = Number((this.priceBookingFee + this.priceFinalCleaning) / 100).toLocaleString("de-DE", {
    				minimumFractionDigits: 2
    			}) + " €";

    			return calcPrice;
    		},
    		isDateInRange: function(fromdate, year) {
    			var fromYear = parseInt(fromdate.substring(0, 4));
    			//console.log('fromYear::', fromYear, "Year::", year);
    			if (fromYear == year) {
    				return true;
    			}
    			return false;
    		}

    	},
    	computed: {
    		getYears: function() {
    			var pricelist = JSON.parse(this.pricelist);

    			var years = [];

    			var i, j;
    			for (i = 0; i < pricelist.length; i++) {
    				for (j = 0; j < pricelist[i].lines.length; j++) {
    					if (pricelist[i].lines[j].fromdate) {
    						var year = pricelist[i].lines[j].fromdate.substring(0, 4);
    						if (years.indexOf(year) < 0) {
    							years.push(year);
    						}
    					}
    				}
    			}
    			return years;
    		}
    	}
    };
</script>