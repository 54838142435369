var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.standardGuests
      ? _c("div", { staticClass: "price-hint" }, [
          _c("strong", [_vm._v("Hinweis:")]),
          _vm._v(
            " Unsere Preise beziehen sich auf eine Grundbelegung von bis zu " +
              _vm._s(_vm.getStandardGuests()) +
              ". Für zusätzliche Personen wird ein Aufpreis gemäß nachfolgender Preisliste erhoben.\n      "
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "table",
      { staticClass: "table pricelist table-hover" },
      _vm._l(_vm.priceArray, function(sg) {
        return _c(
          "tbody",
          [
            _c("tr", { staticClass: "mainrow" }, [
              _c("td", [
                _vm._v(
                  "\n                      " +
                    _vm._s(sg.service.name) +
                    "\n                      "
                ),
                _c("small", [
                  sg.nightRange
                    ? _c("span", [
                        _vm._v(
                          "\n                                " +
                            _vm._s(_vm.voMsg("nights")) +
                            ": " +
                            _vm._s(sg.nightRange.min) +
                            " - " +
                            _vm._s(sg.nightRange.max) +
                            " \n\t\t\t\t\t\t\t\t\t\t"
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  sg.guestRange
                    ? _c("span", [
                        _vm._v(
                          "\n                                " +
                            _vm._s(_vm.voMsg("guests")) +
                            ": " +
                            _vm._s(sg.guestRange.min) +
                            " - " +
                            _vm._s(sg.guestRange.max) +
                            " \n\t\t\t\t\t\t\t\t\t\t"
                        )
                      ])
                    : _vm._e()
                ])
              ]),
              _vm._v(" "),
              _c("td", { staticClass: "text-center" }, [
                sg.price
                  ? _c("span", [
                      _vm._v(_vm._s(_vm.getCalculatedPrice(sg.price)))
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("td", { staticClass: "text-right" }, [
                sg.price && sg.service.calculation != "FLAT"
                  ? _c("div", [
                      sg.service.calculation === "FLAT"
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.voMsg("tpl.service.FLAT")))
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      sg.service.calculation === "NIGHT"
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.voMsg("tpl.service.NIGHT")))
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      sg.service.calculation === "WEEK"
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.voMsg("tpl.service.WEEK")))
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      sg.service.calculation === "MONTH"
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.voMsg("tpl.service.MONTH")))
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      sg.service.perAdult
                        ? _c("small", [
                            _vm._v(" / " + _vm._s(_vm.voMsg("adult")) + " ")
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      sg.service.perChild
                        ? _c("small", [
                            _vm._v(" / " + _vm._s(_vm.voMsg("child")) + " ")
                          ])
                        : _vm._e()
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", [
                  sg.service._id === 45288
                    ? _c("span", [
                        _vm._v(_vm._s(_vm.voMsg("tpl.service.PERSON")))
                      ])
                    : _vm._e()
                ])
              ])
            ]),
            _vm._v(" "),
            _vm._l(sg.lines, function(price) {
              return _c("tr", { staticClass: "subrow" }, [
                _c("td", { staticClass: "subinfo" }, [
                  _c("small", [_vm._v(_vm._s(price.season))]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  price.fromdate
                    ? _c("span", [
                        _vm._v(
                          _vm._s(_vm.getFormattedDate(price.fromdate)) +
                            " - " +
                            _vm._s(_vm.getFormattedDate(price.tilldate))
                        )
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "text-center" }, [
                  _vm._v(
                    "\n                      " +
                      _vm._s(_vm.getCalculatedPrice(price.value)) +
                      "\n                  "
                  )
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "text-right" }, [
                  sg.service.calculation === "FLAT"
                    ? _c("span", [
                        _vm._v(_vm._s(_vm.voMsg("tpl.service.FLAT")))
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  sg.service.calculation === "NIGHT"
                    ? _c("span", [
                        _vm._v(_vm._s(_vm.voMsg("tpl.service.NIGHT")))
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  sg.service.calculation === "WEEK"
                    ? _c("span", [
                        _vm._v(_vm._s(_vm.voMsg("tpl.service.WEEK")))
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  sg.service.calculation === "MONTH"
                    ? _c("span", [
                        _vm._v(_vm._s(_vm.voMsg("tpl.service.MONTH")))
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  sg.service.perAdult
                    ? _c("small", [
                        _vm._v(" / " + _vm._s(_vm.voMsg("adult")) + " ")
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  sg.service.perChild
                    ? _c("small", [
                        _vm._v(" / " + _vm._s(_vm.voMsg("child")) + " ")
                      ])
                    : _vm._e()
                ])
              ])
            })
          ],
          2
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }